import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';

import ScrollIcon from '../../img/icon-scroll-up.svg';

const useStyles = makeStyles(theme => ({
  toTop: {
    zIndex: 2,
    position: 'fixed',
    bottom: '2vh',
    '&:hover, &.Mui-focusVisible': {
      transition: '0.3s'
    },
    [theme.breakpoints.up('xs')]: {
      right: '5%'
    },
    [theme.breakpoints.up('lg')]: {
      right: '6.5%'
    }
  }
}));

const Scroll = ({ showBelow }) => {
  const classes = useStyles();

  const [show, setShow] = useState(!showBelow);

  const handleScroll = () => {
    if (window.pageYOffset > showBelow) {
      if (!show) setShow(true);
    } else {
      if (show) setShow(false);
    }
  };

  const handleClick = () => {
    window[`scrollTo`]({ top: 0, behavior: `smooth` });
  };

  useEffect(() => {
    if (showBelow) {
      window.addEventListener(`scroll`, handleScroll);
      return () => window.removeEventListener(`scroll`, handleScroll);
    }
  });

  return (
    <div>
      {show && (
        <IconButton
          onClick={handleClick}
          className={classes.toTop}
          aria-label="to top"
          component="span"
          size="small"
          disableFocusRipple
          disableRipple
        >
          <img src={ScrollIcon} alt="Scroll to top" />
        </IconButton>
      )}
    </div>
  );
};
export default Scroll;
