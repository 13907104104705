import React from 'react';
const Video = ({ videoSrcURL, videoTitle }) => {
  const stopVideos = () => {
    document.querySelectorAll('iframe').forEach(v => {
      v.src = v.src;
    });
    document.querySelectorAll('video').forEach(v => {
      v.pause();
    });
  };
  return (
    <div id="anniversaryModal" className="modal fade" onClick={stopVideos}>
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content transparent-modal">
          <div className="modal-header">
            <button
              style={{ opacity: 1, fontSize: '40px' }}
              onClick={stopVideos}
              type="button"
              className="close"
              data-dismiss="modal"
              aria-hidden="true"
            >
              &times;
            </button>
          </div>
          <div className="modal-body">
            <div className="embed-container">
              <iframe
                id="anniversaryVideo"
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                data-src={videoSrcURL}
                title={videoTitle}
                frameBorder="0"
                allowFullScreen
              ></iframe>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Video;
