import React, { useRef } from 'react';
import useScrollSnap from 'react-use-scroll-snap';

import { graphql } from 'gatsby';

import Scroll from '../components/scrollToTop';
import Layout from '../components/layout';

import iconPlayer from '../img/icon-player.svg';
import videoThumbnail from '../img/19th-anniversary/anniversary-banner-picture.jpg';
import lineVector from '../img/19th-anniversary/timeline-vector.svg';
import borderLeft from '../img/19th-anniversary/border-top-left.png';
import borderRight from '../img/19th-anniversary/border-top-right.png';
import borderBottom from '../img/19th-anniversary/border-bottom.png';

import section1Image from '../img/19th-anniversary/anniversary-19th-section1-picture.png';
import section2Image from '../img/19th-anniversary/anniversary-19th-section2-picture.png';
import section3Image1 from '../img/19th-anniversary/anniversary-19th-section3-picture1.png';
import section3Image2 from '../img/19th-anniversary/anniversary-19th-section3-picture2.png';
import section4Image from '../img/19th-anniversary/anniversary-19th-section4-picture.png';
import section5Image from '../img/19th-anniversary/anniversary-19th-section5-picture.png';
import section6Image from '../img/19th-anniversary/anniversary-19th-section6-picture.png';
import section7Image from '../img/19th-anniversary/anniversary-19th-section7-picture.png';
import section8Image from '../img/19th-anniversary/anniversary-19th-section8-picture.png';
import section9Image from '../img/19th-anniversary/anniversary-19th-section9-picture.png';
import section10Image1 from '../img/19th-anniversary/anniversary-19th-section10-picture1.png';
import section10Image2 from '../img/19th-anniversary/anniversary-19th-section10-picture2.png';
import section11Image from '../img/19th-anniversary/anniversary-19th-section11-picture.png';
import section12Image from '../img/19th-anniversary/anniversary-19th-section12-picture.png';
import section13Image from '../img/19th-anniversary/anniversary-19th-section13-picture.png';
import section14Image from '../img/19th-anniversary/anniversary-19th-section14-picture.png';
import section15Image from '../img/19th-anniversary/anniversary-19th-section15-picture.png';
import section16Image from '../img/19th-anniversary/anniversary-19th-section16-picture.png';
import section17Image from '../img/19th-anniversary/anniversary-19th-section17-picture.png';
import section18Image from '../img/19th-anniversary/anniversary-19th-section18-picture.png';
import section19Image from '../img/19th-anniversary/anniversary-19th-section19-picture.png';

import HelmetComponent from '../components/helmetcomponent';
import Video from '../components/video';

const pageUrls = require('../constants');

const description1 =
  "Glory Reborn was birthed with the vision to value the life of marginalized mothers and babies. When Hilary and David Overton stepped out in faith to the calling God put on their hearts 19 years ago they have witnessed first hand God's hope, healing, faithfulness, and glory re-born. We are overwhelmed with gratitude what God has done, what He is doing, and the legacy that He is building through Glory Reborn. ";
const description2 = (
  <>
    Inch by inch, and ever so gently, *Mary felt her baby’s soft skin with a loving touch, and
    slowly brought him close to her face as she savored his sweet newborn scent. Mary, blind since
    birth, sees her baby with her heart. She is part of our Sponsor-A-Mom program, which has since
    reached so many marginalized pregnant women; saving the lives of both mothers and babies from
    the poorest of the poor. “I feel like I’m part of the family here. I feel like Glory Reborn is
    here for me.” And today, Mary has returned to Glory Reborn, pregnant with another baby!
    <i style={{ fontSize: '14px' }}> *Name changed for patient privacy</i>
  </>
);
const description3 =
  'Meet Nefa and Bella, our staff since 2003; and Tina, our longest-serving midwife. Glory Reborn has an incredible team who share their heart of compassion for the women of Cebu!';
const description4 =
  '"I am Aiza, from the Badjao tribe, an indigenous community near Glory Reborn. When I came to Glory Reborn, I fell in love with their mission and service. I saw how they cared for moms and babies. Since then, I’ve been bringing Badjao moms to enroll here because here at Glory Reborn, we, Badjao women, are seen, respected, and cared for, not discriminated against. Here at Glory Reborn, they encourage, educate, and empower us Badjao women."';
const description5 =
  'An integral part of our social work, our home visit program addresses the needs of marginalized families by offering services and support that they might not otherwise access. Communities we visit include the local dumpsite and the Badjao, among others.';
const description6 =
  'Glory Reborn received training for ultrasound, and since then, getting an early glimpse of the baby inside the womb has been accessible for mothers.';
const description7 =
  'Every month, we hold a special celebration of life for each baby born in Glory Reborn. Babies receive well-baby checkups, vaccinations, and families receive Gospel sharing, snacks, and perhaps our favorite- baby dedications!';
const description8 =
  'The worst earthquake and Typhoon (Haiyan) hit Cebu, Philippines. One evacuee taking refuge at a tent in an evacuation area, gave birth to her baby at Glory Reborn. (Photo clipping from local newspaper SunStar)';
const description9 =
  'Our HIV/AIDS program was born with the help of a volunteer, Melissa. All of our pregnant mommies receive free HIV education, counselling and testing by our trained staff. Since 2016 we also offer a support group for Women Living With HIV in Cebu as we felt women were being left behind in services addressing this epidemic. Our support group encourages them emotionally, spiritually and practically with health teachings, Bible sharing and nutritional support. We have been privileged to walk with these women as they face stigma, discrimination and challenges, offering a safe space for sharing, learning and fun.';
const description10 =
  'During an ultrasound, we found that baby Gabriella had a cystic hygroma growing on her neck. Eventually Gabriela underwent surgery and did spectacularly well. Without your faithful support and prayers this single mom and baby would never have had a chance at life. Now, Gabriella has grown so healthily and beautifully!';
const description11 =
  '“I love volunteering with Glory Reborn because I share the belief that every woman and baby has the right to quality, compassionate and holistic care regardless of their ability to pay. It has been amazing to witness lives being changed in both patients and staff through the love, care and investment from this ministry and it is my heartfelt desire that many more lives will continue to be cherished and nurtured in the years to come!” - Emma, Peace Corps Volunteer';
const description12 =
  'Babies born from each year since Glory Reborn opened its doors, all lined up behind our founders, David and Hilary Overton.';
const description13 =
  '“How do I put over a decade of watching a life transform into a few words and a lot of tears? I first met Lori on the dark streets of our night outreach at the red light district. Her life had known nothing but neglect, abuse, captivity, and disease. Behind the smile and the silliness was a precious lady who would, over the course of a decade, come to know deeply the meaning of these tender words, life abundantly and the tender embrace of being found by our good shepherd. I am honored, humbled and challenged as I bring a voice to Lori’s life well, Jesus’s life through Lori as I like to put it.” -Hilary, June 2019 Newsletter';
const description14 =
  'It was March 28th when Cebu was placed on lockdown. Glory Reborn was in the middle of what was dubbed the "worst place to live in" during the ongoing pandemic. And yet, YOU, our partners, came with prayers and gifts, and we knew that no pandemic can stop our mission together!';
const description15 =
  'Our laboratory opened its doors in May 5, 2021, making impactful laboratory services accessible to pregnant women.';
const description16 =
  'On December 16, 2021, Typhoon Rai, locally named Odette, ravaged Cebu. One of the strongest to hit the Earth in 2021, it strengthened from a Category 1 to 5 typhoon just hours before landfall. Houses were damaged, and yet, YOU, our partners, came and made it possible for families to rebuild their homes.';
const description17 =
  'Pioneering waterbirth in Cebu, Philippines, Glory Reborn opened its first water birth room, giving expectant moms an alternative, and profoundly helping a mother trust herself, her body and the process of birth.';
const description18 =
  '“At Glory Reborn, they saved my baby’s life! We now feel complete!”, Mommy Melojane told us with a giddy laugh. This was after the birth of her second baby, under the care of our healthcare team at Glory Reborn. Redeeming her from a heart-wrenching first birthing experience at an unnamed hospital where her first baby died an hour after birth; she now encountered the healing that comes from Jesus Christ here at Glory Reborn. “We are so grateful to God for using Glory Reborn. He used people to help us. We are so happy because it is an answered prayer, our budget could not have afforded a Cesarean section with Glory Reborn doctors in a private hospital,” Mommy Melojane said. Healthy Mom, Melojane, Healthy Baby Gideon and hopeful hearts for this family who are now celebrating the fullness of life!';
const description19 =
  'Years ago, this mommy gave birth to her daughter, who is now an expectant mother herself! We are now seeing the birth of generations of Healthy Moms, Healthy Babies, and Hopeful Hearts! What an amazing legacy!';

const LeftSection = ({ year, title, description, button1, button2, image1, image2 }) => {
  return (
    <section className="anniversary-sect">
      <div className="anniversary-sect-wrapper">
        <div className="anniversary-sect-wrapper-r1">
          <div className="anniversary-sect-line-r1-down">
            <img id="line-vector" src={lineVector} />
          </div>
          <div className="anniversary-sect-wrapper-content">
            <h1>{year}</h1>
            <h2>{title}</h2>
            <p>{description}</p>
            <div className="btn-container">
              {button1 && (
                <a className="btn-section" href={pageUrls.DONATE_URL}>
                  {button1}
                </a>
              )}
              {button2 && (
                <a className="btn-section" href={pageUrls.SPONSOR_A_MOM_URL}>
                  {button2}
                </a>
              )}
            </div>
          </div>
        </div>
        <div className="anniversary-sect-wrapper-r2 spacer-top">
          <div className="anniversary-sect-image-container">
            <img id={image2 ? 'section-image-2' : 'section-image'} src={image1} />
            {image2 && <img id="section-image-2" src={image2} />}
          </div>
          <div className="anniversary-sect-line-r2-up">
            <img id="line-vector" src={lineVector} />
          </div>
        </div>
      </div>
    </section>
  );
};

const RightSection = ({ year, title, description, button1, button2, image1, image2 }) => {
  return (
    <section className="anniversary-sect">
      <div className="anniversary-sect-wrapper reverse-order">
        <div className="anniversary-sect-wrapper-r1 spacer-top">
          <div className="anniversary-sect-image-container">
            <img id={image2 ? 'section-image-2' : 'section-image'} src={image1} />
            {image2 && <img id="section-image-2" src={image2} />}
          </div>
          <div className="anniversary-sect-line-r1-up">
            <img id="line-vector" src={lineVector} />
          </div>
        </div>
        <div className="anniversary-sect-wrapper-r2">
          <div className="anniversary-sect-line-r2-down">
            <img id="line-vector" src={lineVector} />
          </div>
          <div className="anniversary-sect-wrapper-content">
            <h1>{year}</h1>
            <h2>{title}</h2>
            <p>{description}</p>
            <div className="btn-container">
              {button1 && (
                <a className="btn-section" href={pageUrls.DONATE_URL}>
                  {button1}
                </a>
              )}
              {button2 && (
                <a className="btn-section" href={pageUrls.SPONSOR_A_MOM_URL}>
                  {button2}
                </a>
              )}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default function Template() {
  const scrollRef = useRef(null);

  useScrollSnap({ ref: scrollRef, duration: 50, delay: 0 });

  return (
    <>
      <Layout nav="fixed-nav">
        <HelmetComponent
          title="19 Years of Glory Reborn"
          description="This August, Glory Reborn turns 19 years old!"
        />
        <Scroll showBelow={250} />
        <div ref={scrollRef}>
          <section className="anniversary-banner">
            <div className="anniversary-banner-video">
              <img id="thumbnail-border-left" src={borderLeft} />
              <img id="thumbnail-border-right" src={borderRight} />
              <img id="thumbnail-border-bottom" src={borderBottom} />
              <div id="video-btn">
              <a href="#anniversaryModal" data-dismiss="modal" data-toggle="modal">
                  <img src={iconPlayer} alt="" />
                </a>
              </div>
              <img id="thumbnail" src={videoThumbnail} />
            </div>
            <div className="anniversary-banner-content">
              <div className="anniversary-banner-content-line">
                <img id="line-vector" src={lineVector} />
              </div>
              <div className="anniversary-banner-content-wrapper">
                <h2>19 Years of Glory Reborn</h2>
                <p>
                  Happy 19 Years Glory Reborn! By providing complete, quality, holistic care
                  together with Jesus and you, our partners, we are seeing health and hope born each
                  and every day. Thank you to all of the churches, friends, family, foundations,
                  partners, advisors, the incredibly hard working team of Glory Reborn, the mothers,
                  babies, and families for being an active part of seeing Healthy Moms, Healthy
                  Babies and Hopeful Hearts in Cebu, Philippines. Come and see how you have created
                  an impact in the lives of moms and babies, come walk with us through the 19 years
                  of Glory Reborn!
                </p>
              </div>
            </div>
          </section>
          <LeftSection
            year="2003"
            title="The Birth of Glory Reborn"
            description={description1}
            button1="Donate"
            button2="Sponsor a Mom"
            image1={section1Image}
          />
          <RightSection
            title="A Mother's Loving Touch"
            description={description2}
            image1={section2Image}
          />
          <LeftSection description={description3} image1={section3Image1} image2={section3Image2} />
          <RightSection
            title="Empowering the Badjao women"
            description={description4}
            button1="Donate"
            button2="Sponsor a Mom"
            image1={section4Image}
          />
          <LeftSection title="Home Visit" description={description5} image1={section5Image} />
          <RightSection
            year="2008"
            title="Ultrasound"
            description={description6}
            image1={section6Image}
          />
          <LeftSection title="Baby Party Time!" description={description7} image1={section7Image} />
          <RightSection year="2013" description={description8} image1={section8Image} />
          <LeftSection
            year="2014"
            title="HIV/AIDS Program"
            description={description9}
            button1="Donate"
            button2="Sponsor a Mom"
            image1={section9Image}
          />
          <RightSection
            year="2014"
            description={description10}
            image1={section10Image1}
            image2={section10Image2}
          />
          <LeftSection year="2015" description={description11} image1={section11Image} />
          <RightSection
            year="2016"
            description={description12}
            button1="Donate"
            button2="Sponsor a Mom"
            image1={section12Image}
          />
          <LeftSection year="2019" description={description13} image1={section13Image} />
          <RightSection
            year="2020"
            title="Lockdown"
            description={description14}
            image1={section14Image}
          />
          <LeftSection
            year="2021"
            title="Laboratory"
            description={description15}
            button1="Donate"
            button2="Sponsor a Mom"
            image1={section15Image}
          />
          <RightSection description={description16} image1={section16Image} />
          <LeftSection
            year="2022"
            title="Waterbirth"
            description={description17}
            image1={section17Image}
          />
          <RightSection description={description18} image1={section18Image} />
          <section className="anniversary-sect">
            <div className="anniversary-sect-wrapper">
              <div className="anniversary-sect-wrapper-r1">
                <div className="anniversary-sect-line-r1-down">
                  <img id="line-vector" src={lineVector} />
                </div>
                <div className="anniversary-sect-wrapper-content">
                  <h2>Birthing Generations!</h2>
                  <p>{description19}</p>
                  <div className="btn-container">
                    <a className="btn-section" href={pageUrls.DONATE_URL}>
                      Donate
                    </a>
                    <a className="btn-section" href={pageUrls.SPONSOR_A_MOM_URL}>
                      Sponsor A Mom
                    </a>
                  </div>
                </div>
              </div>
              <div className="anniversary-sect-wrapper-r2 spacer-top">
                <div className="anniversary-sect-image-container">
                  <img id="section-image" src={section19Image} />
                </div>
              </div>
            </div>
          </section>
        </div>
      </Layout>
      <Video
        videoSrcURL="https://www.youtube.com/embed/pyERGENzyDk"
        videoTitle="Anniversary Special"
      />
    </>
  );
}

export const anniversaryPageQuery = graphql`
  query anniversaryPage($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      frontmatter {
        path
        title
      }
    }
  }
`;
